@use 'navigation_btn_commons' as *;

@include desktopStyles {
  .nav-btn-prev,
  .nav-btn-next {
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus:not(.no-focus, .nav-btn-prev--negative, .nav-btn-next--negative) {
      background-color: var(--swiper-pagination-color);
      color: var(--c_white);
    }
  }
}

@include tabletStyles {
  .nav-btn-prev,
  .nav-btn-next {
    &--negative {
      &:hover,
      &:focus:not(.no-focus) {
        border-color: var(--c_white);
        background-color: transparent;
        color: var(--c_white);
      }
    }
  }
}
