@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.sash {
  @include fontSansSemiBold;
  @include fontSize(12);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  display: block;
  padding: 4px 14px;
  border: solid 1px var(--c_primary);
  background: var(--c_white);
  color: var(--c_primary);
  line-height: 18px;
  letter-spacing: 1.54px;
  text-align: center;
  text-transform: uppercase;

  &[data-tooltip] {
    position: absolute;
    cursor: pointer;
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  &.state-5,
  &.state-10,
  &.state-15 {
    border-color: var(--c_carrot);
    color: var(--c_carrot);
  }

  &.state-2,
  &.state-7,
  &.state-9,
  &.state-12,
  &.state-14,
  &.state-16,
  &.state-18 {
    border-color: var(--c_danger);
    color: var(--c_danger);
  }
}
