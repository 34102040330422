@use '../../base_css/abstracts/abstracts' as *;

@keyframes hvr-pulse {
  25% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.9);
  }
}

.iframe-youtube {
  .fancybox-slide--iframe {
    .fancybox-content {
      width: 100%;
      max-width: $max-width;
      padding-top: 39%;

      .fancybox-close-small {
        top: -3px;
        right: -3px;
      }

      .fancybox-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        margin: 30px;
      }
    }
  }
}

.fancybox-close-small {
  @include icon(close-fat, before, 'xs') {
    color: var(--color-link-primary);
  }

  &::after {
    display: none;
  }
}

.fancybox-bg {
  background: var(--c_blue_700);

  .fancybox-is-open & {
    opacity: 0.7;
  }
}

.fancybox-slide > {
  * {
    &.multi-modal {
      padding: 30px 0 0;
      border: none;
    }
  }

  .fancybox-loading {
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    border: none;
    background: url('../../../images/new/ico_sol_white.svg');
    animation: none;
  }
}

.fancybox-slide--iframe {
  .fancybox-content {
    width: auto;
    height: auto;
  }

  .fancybox-close-small {
    right: 0;
  }
}

.fancybox-image-wrap {
  .with-filter & {
    @include image_filter_simple;
  }
}

.fancy-map {
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 800px;
  padding: 0;

  > div {
    width: 100%;
    height: 100%;
  }
}

.fancybox-next-opening {
  left: 512px;
  max-width: 1024px;

  .fancybox-slide {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: auto !important;
    padding: 0 40px;
    margin: 44px 0;
    overflow: auto;
    border-width: 0;
    background-color: var(--c_white);
    text-align: left;
    vertical-align: middle;

    ul {
      li {
        @include icon(check, before);

        &::before {
          width: 0;
          color: var(--c_green);
          font-weight: 600;
        }

        strong {
          margin-left: 3px;
        }
      }
    }
  }

  .body-content {
    :first-child {
      margin-top: 0;
    }
  }
}

// fix fancybox bug in safari browsers
.fancybox-button svg {
  width: 20px;
}

.fancybox-button svg path {
  stroke-width: 0 !important;
}

@include commonTabletDesktop {
  .fancybox-slide > {
    * {
      &.multi-modal {
        max-height: 100%;
      }

      &#not-logged {
        max-width: 830px;
      }
    }
  }
}

@include commonTabletMobile {
  .fancybox-slide > {
    div {
      max-width: 100%;
      margin-top: 0;

      &#not-logged {
        padding: 0;
      }
    }
  }
}

@include mobileStyles {
  .iframe-youtube {
    .fancybox-slide--iframe {
      .fancybox-content {
        padding-top: 52%;
        border: 0;
      }
    }
  }

  .fancybox-slide > {
    * {
      max-height: 100%;

      &.multi-modal {
        padding: 30px 0;
      }
    }
  }
}
