/*
 * Skeleton
 *
 * Skeleton for lazy blocks. The skeleton used for CSS/JS lazy loading
 * is managed by a different CSS class. This one is applied for full
 * lazy blocks.
 *
 * In hotel pages, height is being fixed for anchor functionality.
 * More about this in comment below.
 *
 * Related HTML in CMS:
 * iberostar-cms/app/views/iberostar/cms/blocks/skeletons/_default.html.erb
 *
 */

@use 'skeleton_commons' as *;

$skeleton_margin_bottom: 40px;

@include desktopStyles {
  // Fixed height for NFH skeletons, so anchors are calculated correctly.
  // So URLs like:
  // https://iberostar.com/hoteles/mallorca/iberostar-albufera-park#fotos
  // will correctly move the user to the anchor.
  //
  // Implemented under client's directions.
  //
  $nfh_blocks_and_heights: (
    'hotel_services_list': calc(291px + 60px - #{$skeleton_margin_bottom}), //detalles del hotel, sin anchor
    'home_tabs': calc(460px + 60px + 60px - #{$skeleton_margin_bottom}), //home tabs, sin anchor, márgenes superior e inferior
    'hotel_information': calc(658px + 60px - #{$skeleton_margin_bottom}), //descripcion, margen inferior
    'information_banner': calc(270px + 50px + 60px - #{$skeleton_margin_bottom}), //information banner, márgenes superior e inferior
    'masonry_gallery': calc(660px + 60px - #{$skeleton_margin_bottom}), //fotos
    'rooms_list_with_filter_module': calc(1226px + 60px - #{$skeleton_margin_bottom}), //habitaciones, margen inferior
    'multi_offers_slider': calc(588px + 60px - #{$skeleton_margin_bottom}), //ofertas, margen inferior
    'gastronomy': calc(630px + 60px - #{$skeleton_margin_bottom}), //gastronomia, margen inferior
    'poi_map': calc(584px + 60px - #{$skeleton_margin_bottom}), //mapa
    'accomodation_regimes': calc(120px + 60px - #{$skeleton_margin_bottom}), //regímenes de alojamiento, sin anchor, margen inferior
    'availability_dates': calc(140px + 60px - #{$skeleton_margin_bottom}), //calendario de precios y disponibilidad, sin anchor y margen inferior
    'information_modules': calc(545px + 60px - #{$skeleton_margin_bottom}), //experiencias, margen inferior
  );

  .e-skeleton {
    @each $block, $height in $nfh_blocks_and_heights {
      &[data-block-layout="#{$block}"] {
        .bg {
          height: $height;
        }
      }
    }

    .bg {
      min-height: 422px;
    }
  }
}

@include tabletStyles {
  $nfh_blocks_and_heights: (
    'hotel_services_list': calc(479px + 60px - #{$skeleton_margin_bottom}), //detalles del hotel, sin anchor, margen inferior
    'home_tabs': calc(450px + 60px + 60px - #{$skeleton_margin_bottom}), //home tabs, sin anchor, márgenes superior e inferior
    'hotel_information': calc(996px + 60px - #{$skeleton_margin_bottom}), //descripcion
    'information_banner': calc(270px + 30px + 60px - #{$skeleton_margin_bottom}), //information banner, márgenes superior e inferior
    'masonry_gallery': calc(434px + 60px - #{$skeleton_margin_bottom}), //fotos, margen inferior
    'rooms_list_with_filter_module': calc(1207px + 60px - #{$skeleton_margin_bottom}), //habitaciones
    'multi_offers_slider': calc(610px + 60px - #{$skeleton_margin_bottom}), //ofertas, margen inferior
    'gastronomy': calc(454px + 60px - #{$skeleton_margin_bottom}), //gastronomia, margen inferior
    'poi_map': calc(484px + 60px - #{$skeleton_margin_bottom}), //mapa, margen inferior
    'accomodation_regimes': calc(187px + 60px - #{$skeleton_margin_bottom}), //regímenes de alojamiento, sin anchor, margen inferior
    'availability_dates': calc(116px - #{$skeleton_margin_bottom}), //calendario de precios y disponibilidad, sin anchor
    'information_modules': calc(452px + 60px - #{$skeleton_margin_bottom}), //experiencias, margen inferior
  );

  .e-skeleton {
    @each $block, $height in $nfh_blocks_and_heights {
      &[data-block-layout="#{$block}"] {
        .bg {
          height: $height;
          min-height: 0;
        }
      }
    }

    .bg {
      min-height: 322px;
    }
  }
}
