@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.e-toggle {
  position: relative;
  cursor: pointer;
  width: 80px;
  height: 40px;
  border: 1px solid var(--c_grey_200);
  border-radius: 20px;

  .e-toggle-marker {
    @include icon(check) {
      padding-top: 4px;
      font-size: 16px;
    }
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translate(0, -50%);
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--c_grey_200);
    color: var(--c_white);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .e-toggle-marker {
      background-color: var(--c_grey_400);
    }
  }

  &.active {
    .e-toggle-marker {
      left: calc(100% - 4px);
      transform: translate(-100%, -50%);
      background-color: var(--c_green);
    }
  }
}
