@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.info-links {
  .info-link {
    display: inline-block;
    margin-right: 15px;
  }

  a {
    @include fontSansBold;
    @include fontSize(14);
    color: var(--color-link-primary);
    line-height: 20px;
    text-decoration: underline;
  }

  .s {
    line-height: 1;

    &.s-picture-gallery {
      padding-right: 3px;
    }
  }
}
