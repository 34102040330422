@use '../../elements/navigation_btn/navigation_btn';

.swiper-customized {
  &.ratio-16-9 {
    .swiper-container {
      position: relative;
      width: 100%;
      height: auto;
      overflow-x: hidden;
      aspect-ratio: 16/9;
    }
  }

  .nav-btn-prev,
  .nav-btn-next {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
  }

  .nav-btn-prev {
    left: 10px;
  }

  .nav-btn-next {
    right: 10px;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}
