@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.nav-btn-prev,
.nav-btn-next {
  position: relative;
  inset: auto;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: solid 2px var(--swiper-navigation-border-color);
  border-radius: 100%;
  background: var(--c_white);
  color: var(--swiper-navigation-border-color);
  text-align: center;

  &.swiper-button-disabled {
    opacity: 0.4;
  }

  &--no-border {
    border: 0;
  }

  &--negative {
    border-color: var(--c_white);
    background: transparent;
    color: var(--c_white);

    &:hover {
      border-color: var(--swiper-navigation-border-color);
    }
  }

  &--with-text {
    @include icon('arrow-right-fat', after, 'xs') {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      margin-left: $space1;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0 11px 0 5px;
    border-radius: 25px;
    text-transform: uppercase;
    transition: padding 1s;

    .nav-btn-text {
      display: inline-block;
      max-width: 0;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: top;
      transition: max-width 1s;
    }

    &::before {
      display: none;
    }

    &.is-expanded {
      padding: 0 $space3;

      .nav-btn-text {
        max-width: 500px;
      }
    }
  }

  &.is-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.nav-btn-prev {
  @include icon('arrow-left-fat', before, 'xs') {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  margin-right: 8px;
}

.nav-btn-next {
  @include icon('arrow-right-fat', after, 'xs') {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  margin-left: 8px;
}

.counter {
  flex: 0 0 auto;

}

.navigation {
  display: flex;
  align-items: center;
}
