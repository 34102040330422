@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

$skeleton_margin_bottom: 40px;

.e-skeleton {
  background: var(--c_white);

  .wrapper {
    margin-bottom: $skeleton_margin_bottom;
    overflow: hidden;
  }

  .bg {
    position: relative;
    background: #FAFAFA;
    background: linear-gradient(to right, var(--c_white) 8%, #FAFAFA 24%, #CFD2DF 84%);
    background-size: 1200px 422px;
    animation: placeHolderShimmer 2s linear 0s infinite normal forwards;
  }
}
