@use '../../../base_css/abstracts/abstracts';

.block-layer {
  will-change: transform;
}

#timer-load-blocks {
  position: fixed;
  top: 40px;
  left: 40px;
  z-index: 9999;
  padding: 10px;
  border: 1px solid #000;
  background-color: var(--c_white);
}
