@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.pill {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  > * {
    flex: 0 0 auto;
    padding: 5px 14px;
  }

  .pill-title {
    @include icon(iberostar-logo, after, 's') {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      margin-top: -1px;
      color: var(--c_white);
    }
    position: relative;
    padding-left: 38px;
    background-color: var(--c_primary);
    color: var(--c_white);
  }

  .pill-discount {
    background-color: var(--c_white);
    color: var(--c_primary);
  }

  &.pill-is-negative .pill-title {
    background-color: var(--c_white);
    color: var(--c_primary);

    &::before {
      border-top-color: var(--c_primary);
      border-left-color: var(--c_primary);
    }
  }
}
