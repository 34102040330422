@use 'sass:map';
@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

//
// Rating circles like tripAdvisor ones
//
.rating-circles {
  @include icon(nil, before) {
    color: var(--c_primary);
  }
  font-size: 13px;
  letter-spacing: 2px;

  // Rating
  &.r1 {
    &::before {
      content: '#{map.get($icons, rating-05)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}';
    }
  }

  &.r2 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}';
    }
  }

  &.r3 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-05)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}';
    }
  }

  &.r4 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}';
    }
  }

  &.r5 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-05)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}';
    }
  }

  &.r6 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-00)}#{map.get($icons, rating-00)}';
    }
  }

  &.r7 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-05)}#{map.get($icons, rating-00)}';
    }
  }

  &.r8 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-00)}';
    }
  }

  &.r9 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-05)}';
    }
  }

  &.r10 {
    &::before {
      content: '#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}#{map.get($icons, rating-10)}';
    }
  }

  // Color variations
  // Default is grey
  &.blue {
    &::before {
      color: #3C3A38;
    }
  }

  &.green {
    &::before {
      color: #07A54E;
    }
  }

  &.yellow {
    &::before {
      color: #E5B248;
    }
  }
}
