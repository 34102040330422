@use 'info_links_commons' as *;

.ada {
  .info-link {
    .s {
      &::after {
        padding: 0;
        border-radius: 0;
        background: none;
      }
    }
  }
}
